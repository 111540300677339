import * as Yup from "yup";

export const EmergencyFormValidation = Yup.object().shape({
  emer_fname: Yup.string()
    .trim()
    .required("screens.emergency.pleaseEnterFirstName"),
  emer_lname: Yup.string()
    .trim()
    .required("screens.emergency.pleaseEnterLastName"),
  emer_relation_id: Yup.string().required(
    "screens.emergency.selectRelationship"
  ),
  emer_primaryphone: Yup.string()
    .trim()
    .required("screens.emergency.PleaseEnterPhoneNumber")
    .min(10, "screens.emergency.invalidPhoneNumber"),
  emer_secondaryphone: Yup.string()
    .trim()
    .min(10, "screens.emergency.invalidPhoneNumber"),
});

export const physicianTherapistFormValidation = Yup.object().shape({
  physician_value: Yup.string()
    .trim()
    .required("screens.errorMessages.physicianType"),
  phy_fname: Yup.string().trim().required("screens.errorMessages.firstName"),
  phy_lname: Yup.string().trim().required("screens.errorMessages.lastName"),
  phy_primaryphone: Yup.string()
    .trim()
    .required("screens.errorMessages.phoneNumber")
    .min(10, "screens.errorMessages.invalidPhoneNumber"),
  phy_email: Yup.string().email(`screens.errorMessages.invalidEmail`),
});

export const medicalInsuranceFormValidation = Yup.object().shape({
  ins_provider: Yup.string()
    .trim()
    .required("screens.errorMessages.ins_provider"),
  ins_member: Yup.string().trim().required("screens.errorMessages.ins_member"),
  ins_subcriber: Yup.string()
    .trim()
    .required("screens.errorMessages.ins_subcriber"),
  ins_memberid: Yup.string()
    .trim()
    .required("screens.errorMessages.ins_memberid"),
  ins_providerphone: Yup.string()
    .trim()
    .min(10, "screens.errorMessages.invalidPhoneNumber"),
});

export const precriptionFormValidation = Yup.object().shape({
  // pres_medicationtype: Yup.string().trim().required('Please select medication type'),
  pres_medication: Yup.string()
    .trim()
    .required("screens.errorMessages.pres_medication"),
  pres_medicationtype: Yup.string()
    .trim()
    .required("screens.errorMessages.pres_medicationtype"),
  pres_pharm_phone: Yup.string()
    .trim()
    .min(10, "screens.errorMessages.invalidPhoneNumber"),
});

export const ConditionsForm = Yup.object({});

export const conditionsValidation = Yup.object().shape({
  cond_info: Yup.string().trim().required("screens.errorMessages.cond_info"),
});
export const personalInfoFormValidation = Yup.object().shape({
  fname: Yup.string().trim().required("screens.errorMessages.firstName"),
  lname: Yup.string().trim().required("screens.errorMessages.lastName"),
  homephone: Yup.string()
    .trim()
    .min(10, "screens.errorMessages.invalidPhoneNumber"),
  workphone: Yup.string()
    .trim()
    .min(10, "screens.errorMessages.invalidPhoneNumber"),
  cellphone: Yup.string()
    .trim()
    .min(10, "screens.errorMessages.invalidPhoneNumber"),
});
export const allergiesValidation = Yup.object().shape({
  allergytype: Yup.string()
    .trim()
    .required("screens.errorMessages.allergytype"),
});

export const immunizationValidation = (datesCount) => {
  return Yup.object().shape({
    immu_vaccineslist: Yup.string()
      .trim()
      .required("screens.errorMessages.immu_vaccineslist"),
    immu_date: Yup.object().shape(
      Array.from({ length: datesCount }).reduce((acc, _, index) => {
        acc[`val${index + 1}`] = Yup.string().required(
          `screens.errorMessages.date`
        );
        return acc;
      }, {})
    ),
  });
};

export const FamilyHistoryFormValidation = Yup.object().shape({
  family_member: Yup.string()
    .trim()
    .required("screens.errorMessages.family_member"),
});

export const surgeriesAndTreatmentsValidation = Yup.object().shape({
  surgery_date: Yup.string()
    .trim()
    .required("screens.errorMessages.surgery_date"),
  surgery_type: Yup.string()
    .trim()
    .required("screens.errorMessages.surgery_type"),
  // physician_name: Yup.string()
  //   .trim()
  //   .required("screens.errorMessages.physician_name"),
});

export const AddMemberFormValidation = Yup.object().shape({
  membership_num: Yup.string()
    .trim()
    .required("screens.errorMessages.membership_num"),
  relation_id: Yup.string()
    .trim()
    .required("screens.errorMessages.relation_id"),
  user_fname: Yup.string().when("relation_id", (relation_id, field) => {
    return relation_id[0] !== "1"
      ? field.required("screens.errorMessages.firstName")
      : field;
  }),
  user_lname: Yup.string().when("relation_id", (relation_id, field) => {
    return relation_id[0] !== "1"
      ? field.required("screens.errorMessages.lastName")
      : field;
  }),
  role_id: Yup.string().when("relation_id", (relation_id, field) => {
    return relation_id[0] !== "1"
      ? field.required("screens.errorMessages.role_id")
      : field;
  }),
  user_email: Yup.string().when("relation_id", (relation_id, field) => {
    return relation_id[0] !== "1"
      ? field
          .required("screens.errorMessages.user_email")
          .email(`screens.errorMessages.invalidEmail`)
      : field;
  }),
  relation_name: Yup.string().when("relation_id", (relation_id, field) => {
    return relation_id[0] === "9" || relation_id[0] === "7"
      ? field.required("screens.errorMessages.relation_name")
      : field;
  }),
});

export const documentsValidation = Yup.object().shape({
  docum_date: Yup.string().trim().required("screens.errorMessages.docum_date"),
  docum_name: Yup.string().trim().required("screens.errorMessages.docum_name"),
  docum_file: Yup.mixed().required("screens.errorMessages.docum_file"),
});
